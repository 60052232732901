<template>
    <div class="sub_container">
      <div class="sub_wrapper">
        <h2 class="sub-title1">
          {{ $t('menu4-list') }}
          <span class="bottom">
            <el-tooltip class="item" effect="dark"
                        :content="$t('validatorNotice')"
                        placement="bottom-start">
              <el-button><i class="el-icon-info"></i></el-button>
            </el-tooltip>
          </span>
        </h2>
          <Stable :columnes="columnes_data"
                  :tableData="tableData_data"
                  :total_data = "total_data"
                  :loading="loading"
                  :pageActive =pageActive></Stable>
      </div>
    </div>
</template>
<script>
import Stable from '@/components/Stable.vue';

export default {
  name: 'Validators',
  components: {
    Stable,
  },
  data() {
    return {
      loading: true,
      columnes_data: [{
        label: 'columnValidatorId',
        prop: 'moniker',
        width: '20%',
        class: '',
        router: 'validatorDetail',
        key: 'operator_address',
      },
      {
        label: 'columnVotingPower',
        prop: 'voting_power',
        width: '20%',
        class: '',
        router: '',
        key: '',
      },
      {
        label: 'columnGURUCoin',
        prop: 'tokens',
        width: '20%',
        class: 'mr50',
        router: '',
        key: '',
      },
      {
        label: 'columnCommission',
        prop: 'commission',
        width: '20%',
        class: '',
        router: '',
        key: '',
      }],
      tableData_data: [],
      total_data: 0,
      pageActive: false,
    };
  },
  methods: {
    getData() {
      this.$axios.get('/validators')
        .then((res) => {
          if (res.data.ret_code === 200) this.total_data = res.data.data.tot_bond;
          this.tableData_data = res.data.data.validators;
          this.total_data = this.tableData_data.length;
        })
        .catch((error) => { this.$message.error(error); })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>
